<template>
  <v-btn
    :class="`${classes}`"
    :disabled="disabled"
    :title="lang('ChecklistBuilder.titles.adopt_value')"
    data-quick-button-action-view="adopt_value"
    type="button"
    small
  >
    <v-icon>
      mdi-chevron-left
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ChecklistCellButtonTypeAdoptValue',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'buttontype',
    'data'
  ],
  computed: {
    dataViewer() {
      const builder = this.data.ChecklistBuilder
      return builder.getCellDataViewer(this.checklist.index, this.x, this.y) || {}
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      const builder = this.data.ChecklistBuilder
      return this.state.disabled || !builder.getSetting('ONLINE')
    }
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    }
  }
}
</script>
